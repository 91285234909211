import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const findUsClick = () => {
    setToggleMenu(false);
    document.getElementById('contact').scrollIntoView();
  };
  const classicCocktailsClick = () => {
    setToggleMenu(false);
    document.getElementById('classicCocktails').scrollIntoView();
  };
  const vodkaDrinksClick = () => {
    setToggleMenu(false);
    document.getElementById('vodkaDrinks').scrollIntoView();
  };
  const ginDrinksClick = () => {
    setToggleMenu(false);
    document.getElementById('ginDrinks').scrollIntoView();
  };
  const whiskeyClick = () => {
    setToggleMenu(false);
    document.getElementById('whiskey').scrollIntoView();
  };
  const beersClick = () => {
    setToggleMenu(false);
    document.getElementById('beers').scrollIntoView();
  };
  const shotsClick = () => {
    setToggleMenu(false);
    document.getElementById('shots').scrollIntoView();
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.munchia} alt="app__logo" className="app__navbar-logo-name" />
      </div>
      <ul className="app__navbar-links">
        <li><button type="button" className="nav__button" onClick={classicCocktailsClick}>Classic Cocktails</button></li>
        <li><button type="button" className="nav__button" onClick={vodkaDrinksClick}>Vodka Drinks</button></li>
        <li><button type="button" className="nav__button" onClick={ginDrinksClick}>Gin Drinks</button></li>
        <li><button type="button" className="nav__button" onClick={whiskeyClick}>Whiskey</button></li>
        <li><button type="button" className="nav__button" onClick={beersClick}>Beers</button></li>
        <li><button type="button" className="nav__button" onClick={shotsClick}>Shots</button></li>
        <li><button type="button" className="nav__button" onClick={findUsClick}>Find Us</button></li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><button type="button" className="nav__button" onClick={classicCocktailsClick}>Classic Cocktails</button></li>
              <li><button type="button" className="nav__button" onClick={vodkaDrinksClick}>Vodka Drinks</button></li>
              <li><button type="button" className="nav__button" onClick={ginDrinksClick}>Gin Drinks</button></li>
              <li><button type="button" className="nav__button" onClick={whiskeyClick}>Whiskey</button></li>
              <li><button type="button" className="nav__button" onClick={beersClick}>Beers</button></li>
              <li><button type="button" className="nav__button" onClick={shotsClick}>Shots</button></li>
              <li><button type="button" className="nav__button" onClick={() => findUsClick()}>Find Us</button></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

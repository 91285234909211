import React from 'react';

import { FindUs, Gallery, SpecialMenu, Footer } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (

  <div>
    <Navbar />
    <SpecialMenu />
    <Gallery />
    <FindUs />
    <Footer />
  </div>
);

export default App;

import React from 'react';
// import { FiInstagram } from 'react-icons/fi';
// import { FaWhatsapp } from 'react-icons/fa';

import { FooterOverlay } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    {/* <Newsletter /> */}

    <div className="app__footer-links">
      {/* <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">9 W 53rd St, New York, NY 10019, USA</p>
        <p className="p__opensans">+1 212-344-1230</p>
        <p className="p__opensans">+1 212-555-1230</p>
      </div> */}

      <div className="app__footer-links_logo">
        <img src={images.munchia} alt="footer_logo" />
        <img src={images.beer} className="spoon__img" style={{ marginTop: 15 }} />
        {/* <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <a href="https://www.instagram.com/munch_ia/"> <FiInstagram /> </a>
          <a href="https://wa.me/71155113"> <FaWhatsapp /> </a>
        </div> */}
      </div>

      {/* <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">08:00 am - 12:00 am</p>
        <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">07:00 am - 11:00 pm</p>
      </div> */}
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">© 2023 Munchia. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;

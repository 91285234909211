import bg from '../assets/bg.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import findus from '../assets/findus.png';
import quote from '../assets/quote.png';
import munchia from '../assets/munchia.png';
import beer from '../assets/beer.png';
import toast from '../assets/toast.png';
import cheers from '../assets/cheers.png';
import glass from '../assets/glass.png';
import drinks from '../assets/drinks.png';
import cocktail from '../assets/cocktail.png';
import munchia01 from '../assets/munchia01.png';
import munchia02 from '../assets/munchia02.png';
import munchia03 from '../assets/munchia03.png';
import munchia04 from '../assets/munchia04.png';
import drinkbeer from '../assets/drinkbeer.png';
import bgSmall from '../assets/bgSmall.png';
import menuSmall from '../assets/menuSmall.png';

export default {
  bg,
  menu,
  overlaybg,
  spoon,
  findus,
  quote,
  munchia,
  beer,
  toast,
  cheers,
  glass,
  drinks,
  cocktail,
  munchia01,
  munchia02,
  munchia03,
  munchia04,
  drinkbeer,
  bgSmall,
  menuSmall,
};

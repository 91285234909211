import React, { useState, useEffect } from 'react';
import { client } from '../../client';

import { SubHeading, MenuItem } from '../../components';
// import { images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => {
  const [signatureCocktails, setSignatureCocktails] = useState([]);
  const [shots, setShots] = useState([]);
  const [classicCocktails, setClassicCocktails] = useState([]);
  const [beers, setBeers] = useState([]);
  const [ginDrinks, setGinDrinks] = useState([]);
  const [vodkaDrinks, setVodkaDrinks] = useState([]);
  const [whiskey, setWhiskey] = useState([]);

  const [showMoreSignatureCocktails, setShowMoreSignatureCocktails] = useState(false);
  const [showMoreShots, setShowMoreShots] = useState(false);
  const [showMoreClassicCocktails, setShowMoreClassicCocktails] = useState(false);
  const [showMoreBeers, setShowMoreBeers] = useState(false);
  const [showMoreGinDrinks, setShowMoreGinDrinks] = useState(false);
  const [showMoreVodkaDrinks, setShowMoreVodkaDrinks] = useState(false);
  const [showMoreWhiskey, setShowMoreWhiskey] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const signatureCocktailsData = await client.fetch('*[_type == "signatureCocktails"]');
      setSignatureCocktails(signatureCocktailsData);

      const shotsData = await client.fetch('*[_type == "shots"]');
      setShots(shotsData);

      const classicCocktailsData = await client.fetch('*[_type == "classicCocktails"]');
      setClassicCocktails(classicCocktailsData);

      const beersData = await client.fetch('*[_type == "beers"]');
      setBeers(beersData);

      const ginDrinksData = await client.fetch('*[_type == "ginDrinks"]');
      setGinDrinks(ginDrinksData);

      const vodkaDrinksData = await client.fetch('*[_type == "vodkaDrinks"]');
      setVodkaDrinks(vodkaDrinksData);

      const whiskeyData = await client.fetch('*[_type == "whiskey"]');
      setWhiskey(whiskeyData);
    };

    fetchData();
  }, []);

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <p className="p__opensans__quotes">&quot;The best way to find yourself is with a drink in your hand.&quot;</p>
        <SubHeading />
        <h1 className="headtext__cormorant">Everyday&apos;s Specials</h1>
      </div>

      <div className="app__specialMenu-menu" id="signatureCocktails">
        <div className="app__specialMenu-menu_wine  flex__center">
          <p className="app__specialMenu-menu_heading">House Signature</p>
          <div className="app__specialMenu_menu_items">
            {signatureCocktails.slice(0, showMoreSignatureCocktails ? signatureCocktails.length : 5).map((signatureCocktail, index) => (
              <MenuItem key={index} title={signatureCocktail.name} price={`LBP ${signatureCocktail.price}`} tags={signatureCocktail.description} />
            ))}
          </div>
          {signatureCocktails.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreSignatureCocktails(!showMoreSignatureCocktails);
                if (showMoreSignatureCocktails) {
                  document.getElementById('signatureCocktails').scrollIntoView();
                }
              }}
            >
              {showMoreSignatureCocktails ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

      <div className="app__specialMenu-menu" id="classicCocktails">
        <div className="app__specialMenu-menu_wine  flex__center">
          <p className="app__specialMenu-menu_heading">Classic Cocktails</p>
          <div className="app__specialMenu_menu_items">
            {classicCocktails.slice(0, showMoreClassicCocktails ? classicCocktails.length : 5).map((classicCocktail, index) => (
              <MenuItem key={index} title={classicCocktail.name} price={`LBP ${classicCocktail.price}`} tags={classicCocktail.description} />
            ))}
          </div>
          {classicCocktails.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreClassicCocktails(!showMoreClassicCocktails);
                if (showMoreClassicCocktails) {
                  document.getElementById('classicCocktails').scrollIntoView();
                }
              }}
            >
              {showMoreClassicCocktails ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

      <div className="app__specialMenu-menu" id="vodkaDrinks">
        <div className="app__specialMenu-menu_wine flex__center">
          <p className="app__specialMenu-menu_heading">Vodka Drinks</p>
          <div className="app__specialMenu_menu_items">
            {vodkaDrinks.slice(0, showMoreVodkaDrinks ? vodkaDrinks.length : 5).map((vodkaDrink, index) => (
              <MenuItem key={index} title={vodkaDrink.name} price={`LBP ${vodkaDrink.price}`} tags={vodkaDrink.description} />
            ))}
          </div>
          {vodkaDrinks.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreVodkaDrinks(!showMoreVodkaDrinks);
                if (showMoreVodkaDrinks) {
                  document.getElementById('vodkaDrinks').scrollIntoView();
                }
              }}
            >
              {showMoreVodkaDrinks ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

      <div className="app__specialMenu-menu" id="ginDrinks">
        <div className="app__specialMenu-menu_wine flex__center">
          <p className="app__specialMenu-menu_heading">Gin Drinks</p>
          <div className="app__specialMenu_menu_items">
            {ginDrinks.slice(0, showMoreGinDrinks ? ginDrinks.length : 5).map((ginDrink, index) => (
              <MenuItem key={index} title={ginDrink.name} price={`LBP ${ginDrink.price}`} tags={ginDrink.description} />
            ))}
          </div>
          {ginDrinks.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreGinDrinks(!showMoreGinDrinks);
                if (showMoreGinDrinks) {
                  document.getElementById('ginDrinks').scrollIntoView();
                }
              }}
            >
              {showMoreGinDrinks ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

      <div className="app__specialMenu-menu" id="whiskey">
        <div className="app__specialMenu-menu_wine flex__center">
          <p className="app__specialMenu-menu_heading">Whiskey</p>
          <div className="app__specialMenu_menu_items">
            {whiskey.slice(0, showMoreWhiskey ? whiskey.length : 5).map((whiskeyDrink, index) => (
              <MenuItem key={index} title={whiskeyDrink.name} price={`LBP ${whiskeyDrink.price}`} tags={whiskeyDrink.description} />
            ))}
          </div>
          {whiskey.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreWhiskey(!showMoreWhiskey);
                if (showMoreWhiskey) {
                  document.getElementById('whiskey').scrollIntoView();
                }
              }}
            >
              {showMoreWhiskey ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

      <div className="app__specialMenu-menu" id="beers">
        <div className="app__specialMenu-menu_wine flex__center">
          <p className="app__specialMenu-menu_heading">Beers</p>
          <div className="app__specialMenu_menu_items">
            {beers.slice(0, showMoreBeers ? beers.length : 5).map((beer, index) => (
              <MenuItem key={index} title={beer.name} price={`LBP ${beer.price}`} tags={beer.description} />
            ))}
          </div>
          {beers.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreBeers(!showMoreBeers);
                if (showMoreBeers) {
                  document.getElementById('beers').scrollIntoView();
                }
              }}
            >
              {showMoreBeers ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

      <div className="app__specialMenu-menu" id="shots">
        <div className="app__specialMenu-menu_wine flex__center">
          <p className="app__specialMenu-menu_heading">Shots</p>
          <div className="app__specialMenu_menu_items">
            {shots.slice(0, showMoreShots ? shots.length : 5).map((shot, index) => (
              <MenuItem key={index} title={shot.name} price={`LBP ${shot.price}`} tags={shot.description} />
            ))}
          </div>
          {shots.length > 5 && (
            <button
              type="button"
              className="showMoreButton"
              onClick={() => {
                setShowMoreShots(!showMoreShots);
                if (showMoreShots) {
                  document.getElementById('shots').scrollIntoView();
                }
              }}
            >
              {showMoreShots ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </div>

    </div>
  );
};

export default SpecialMenu;

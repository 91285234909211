import React from 'react';

import { FiInstagram } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <h1 className="app__footer-links_icons">
        <a href="https://www.instagram.com/munch_ia/"> <FiInstagram /> </a>
        <a href="https://wa.me/+96171155113"> <FaWhatsapp /> </a>
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">Badaro Military hospital street, facing villa badaro</p>
        <p className="p__opensans">Badaro, Beirut, Lebanon</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
        <p className="p__opensans">Mon - Thu: 04:00 pm - 12:30 am</p>
        <p className="p__opensans">Fri - Sat: 04:00 pm - 02:00 am</p>
        <p className="p__opensans">Sun: 08:00 pm - 12:30 am</p>
        <p className="p__opensans">Happy Hour: 06:00 pm - 08:00 pm</p>

      </div>
      <button type="button" className="custom__button" style={{ marginTop: '2rem' }}><a href="https://goo.gl/maps/KSfDC3TtYggxmJJQ9">Location</a></button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
